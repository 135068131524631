import axiosInstance from "../utils/helper";
import { idPropType, paymentActionType, paymentTypes } from "../types/commonTypes";
import { showErrorToast, showSuccessToast } from "../utils/helperFunctions";

const VENDOR_PAYMENT_API = {
  createVendorPayment: (params: paymentTypes) => {
    return axiosInstance.post("/payment-requests", params);
  },
  updateVendorPayment: (params: paymentTypes, id: string) => {
    return axiosInstance.put(`/payment-requests/${id}`, params);
  },
  getVendorByGstin: (gstin_number: string) => {
    return axiosInstance.get(`/vendors/gstin-search/${gstin_number}`);
  },
  getVendorByPan: (pan: string) => {
    return axiosInstance.get(`/vendors/pan-search/${pan}`);
  },
  getPaymentRequests: (params: Record<string, unknown>) => {
    return axiosInstance.get(`/payment-requests`, { params });
  },
  getPayerRequests: (params: Record<string, unknown>) => {
    return axiosInstance.get(`/vendors/payment-requests-filter`, { params });
  },
  getPaymentHistory: (params: Record<string, unknown>) => {
    return axiosInstance.get(`/vendors/payment-requests-filter/history`, { params });
  },
  postPaymentRequestAction: (id: idPropType, params: paymentActionType) => {
    return axiosInstance.post(`/payment-requests/${id}/change-status/`, params);
  },
  getPayment: (id: string) => {
    return axiosInstance.get(`/payment-requests/${id}`);
  },
  getTdsSection: () => {
    return axiosInstance.get(`/vendors/search/tds-section`);
  },
  getClassOfExpense: () => {
    return axiosInstance.get(`/vendors/search/class-of-expense`);
  },
  getActions: (id: string) => {
    return axiosInstance.get(`/vendor-payment-req-action/${id}`);
  },
  getTdsRate: (params: Record<string, unknown>, id: string) => {
    return axiosInstance.get(`/payment-requests/${id}/calculate-tds/`, { params });
  },
  postSendOtp: () => {
    return axiosInstance.post(`/vendor/payments/send-transaction-otp`);
  },
  sendVendorPayment: (params: { otp: string; payment_ids: (number | string)[] }) => {
    return axiosInstance.post(`/vendor/payments/make-vendor-payment`, params);
  },
  checkPaymentStatus: () => {
    return axiosInstance.post(`/vendor/payments/check-payment-status`);
  }
};

export default VENDOR_PAYMENT_API;

export const onPaymentAction = async (id: idPropType, params: paymentActionType) => {
  try {
    await VENDOR_PAYMENT_API.postPaymentRequestAction(id, params);
    showSuccessToast("Payment request has been updated successfully");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    showErrorToast(error?.response?.data?.detail);
  }
};
