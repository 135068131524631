/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from "antd";
import axios, { AxiosError } from "axios";
import moment from "moment";
import { idPropType, vendorTypeProps } from "../types/commonTypes";
import { DRAFT, PAYMENT_ACTIONS, payment_status, timeForToastError } from "./constants";

export const onError = (error: AxiosError) => {
  const msg = error?.response?.data?.message || "Somethins went wrong, Please try again later!";
  message.error(msg, timeForToastError);
};

export const showErrorToast = (msg: string) => {
  message.error(msg || "Something went wrong, Please try again later!", timeForToastError);
};

export const showSuccessToast = (msg: string) => {
  message.success(msg || "Successfully done!", timeForToastError);
};

interface DownloadCSV {
  uri: string;
  fileName: string;
}

export const downloadCSV = async ({ uri, fileName }: DownloadCSV) => {
  try {
    const response = await axios.get(uri);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error: any) {
    const msg = error?.response?.data?.message || "Something went wrong, Please try again later!";
    showErrorToast(msg);
  }
};

export function getFormData(object: any) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

export const isNullUndefEmptyStr = (obj: any, fields: string[]) => {
  if (obj === null || obj === undefined || obj === "") return true;
  for (let i = 0; i < fields.length; i++) {
    if (obj[fields[i]] === null || obj[fields[i]] === undefined || obj[fields[i]] === "")
      return true;
  }
  return false;
};

export const formatArray = (array: []) => {
  const newArray: Record<string, unknown>[] = [];
  if (array && array.length) {
    array.forEach((obj: { id: string; name?: string; title?: string }) => {
      newArray.push({
        key: obj.id || obj,
        label: obj.name || obj.title || obj
      });
    });
  }
  return newArray;
};

// GET PERMISSIONS DATA
export const getPermissionsData = () => {
  try {
    const ROLE = localStorage.getItem("ROLE");
    const USER_DETAILS = localStorage.getItem("USER_DETAILS");
    const { roles } = JSON.parse(USER_DETAILS || "{}");
    const { permission } = roles.find((role: any) => role?.role === ROLE) || {};
    const data = permission;
    if (data && data?.length > 0) {
      return data;
    }
    return [];
  } catch (error) {
    console.log({ error });
  }
};

export const formValidationCheck = (form: { getFieldsError: () => any[] }) => {
  if (form.getFieldsError().filter(({ errors }) => errors.length).length > 0) {
    const error = form.getFieldsError().filter(({ errors }) => errors.length)[0].errors[0];
    showErrorToast(error);
    return 1;
  } else {
    return 0;
  }
};

export const generateFilterString = (searchHearders: any) => {
  let searchString = "";
  for (const [key, value] of Object.entries(searchHearders)) {
    if (searchString && value) {
      searchString += ",";
    }
    if (value) searchString += value && `${key}:${value}`;
  }
  return searchString;
};

export const setLocalStorage = (name: string, data: any) => {
  const response = JSON.stringify(data);
  localStorage.setItem(name, response);
};

export const getlocalStorage = (name: string) => {
  const response = localStorage.getItem(name);
  return response ? JSON.parse(response) : null;
};

export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0;

export const removelocalStorage = (name: string) => localStorage.removeItem(name);

export const formatDate = (date: string) => (date ? moment(date).format("DD-MM-YYYY") : "");

export const onHandleChangeNumeric = (value: any) => {
  if (!Number(value)) {
    return;
  }
};

export const getPaymentStatus = (id: idPropType) => payment_status.find((item) => item?.id == id);

export const getPaymentStatusColor = (status: string) =>
  payment_status.find((item) => item?.status == status)?.color;

export const getRejectAction = (status: string) => {
  switch (status) {
    case "1":
      return PAYMENT_ACTIONS.REJECT_FL1;
    case "2":
      return PAYMENT_ACTIONS.REJECT_FL2;
    case "3":
      return PAYMENT_ACTIONS.REJECT_PAYER;
    default:
      return PAYMENT_ACTIONS.REJECT_FL1;
  }
};

export const getReviseActions = (status: string, send_to: string) => {
  switch (status) {
    case "1":
      return `revise_fl1_to_${send_to}`;
    case "2":
      return `revise_fl2_to_${send_to}`;
    case "3":
      return `revise_payer_to_${send_to}`;
    default:
      return `revise_fl1_to_${send_to}`;
  }
};

export const redirect = (role: string, setUrl: any) => {
  switch (role) {
    case "maker":
      setUrl("/vendors/vendor-list");
      break;
    case "admin":
      setUrl("/users/all");
      break;
    case "user_manager":
      setUrl("/users/all");
      break;
    case "payer":
      setUrl("/vendor-payments/all");
      break;
    case "finance_level_1":
      setUrl("/vendors/vendor-list");
      break;
    case "finance_level_2":
      setUrl("/vendors/vendor-list");
      break;
    default:
      setUrl("/");
  }
};

export const setUserLocalStorage = (data: {
  JWT_TOKEN: string;
  ACCESS_TOKENS?: string;
  FULL_NAME: string;
  EMPLOYEE_CODE: string;
  USER_ID: string;
  ROLES: string;
  ROLE: string;
  PERMISSIONS_DATA?: string;
}) => {
  type userProps =
    | "JWT_TOKEN"
    | "ACCESS_TOKENS"
    | "FULL_NAME"
    | "EMPLOYEE_CODE"
    | "USER_ID"
    | "ROLES"
    | "ROLE"
    | "PERMISSIONS_DATA";

  for (const prop in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(prop)) {
      const element = data[prop as userProps];
      element && localStorage.setItem(prop as userProps, element);
    }
  }
};

export const getPaymentSteps = (id: idPropType) => {
  const paymentSteps = [
    {
      title: "Basic Details",
      path: `/vendor-payments/${id}`
    },
    {
      title: "Financial Details",
      path: `/vendor-payments/${id}/finance-details`
    }
  ];
  return paymentSteps;
};

export const getVendorLogsColor = (status: string) => {
  switch (status) {
    case "Vendor Approved":
      return "green";
    case "Vendor Created":
      return "orange";
    default:
      return "blue";
  }
};

export const getVendorOnboardingSteps = (data: vendorTypeProps) => {
  const ONBOARDING_STEPS = [
    {
      title: `Add Basic Info`,
      check: data?.id
    },
    {
      title: "Add Contacts",
      check: data?.contacts?.length
    },
    {
      title: `Add Banks ${data?.is_international ? "(Optional)" : ""}`,
      check: data?.is_international ? true : data?.bank_accounts?.length
    },
    {
      title: "Add Addresses",
      check: data?.addresses?.length
    },
    {
      title: `Add Marketers (Optional)`,
      check: true
    },
    {
      title: "Add Owners",
      check: data?.owners?.length
    },
    {
      title: "Add Documents",
      check:
        (data?.pan_document && data?.pan_number) ||
        data?.msme_document ||
        data?.incorporation_document ||
        data?.agreement_document ||
        data?.incorporation_document ||
        data?.form_10f_document ||
        data?.no_pe_certificate_document ||
        data?.tax_residency_certificate_document ||
        data?.is_international
    },
    {
      title: "Send for Approval",
      check: data?.status !== DRAFT
    }
  ];

  return ONBOARDING_STEPS;
};

export const getVendorNameLogo = (name: string) => {
  if (name) {
    const nameArray = name.split(" ");
    const logo = nameArray.map((item) => item[0]).join("");
    return logo.toLocaleUpperCase();
  }
  return "";
};
