import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import styles from "./breadcrumb.module.css";

interface BreadcrumbProps {
  pathname: string;
}

function CustomBreadcrumb({ pathname }: BreadcrumbProps) {
  const paths = pathname.split("/");
  paths.shift();
  let path = "";
  return (
    <Breadcrumb className={styles["breadcrumb-container"]}>
      {paths.map((item, i) => {
        path = path + "/" + item.toLocaleLowerCase();
        return (
          <Breadcrumb.Item key={item}>
            <Link
              to={path}
              style={{
                pointerEvents: i === 0 ? "none" : "auto"
              }}>
              {item.toLocaleUpperCase()}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
}

export default CustomBreadcrumb;
