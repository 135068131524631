import { vendorTypeProps } from "../../types/commonTypes";
import { ActionTypes } from "../action-types";
import { Action } from "../actions";
export interface vendorState {
  error: string | null;
  data: vendorTypeProps;
  loading: boolean;
}

const initialState = {
  data: {} as vendorState["data"],
  error: null,
  loading: false
};

const vendorReducers = (state: vendorState = initialState, action: Action): vendorState => {
  switch (action.type) {
    case ActionTypes.GET_VENDOR_DETAILS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case ActionTypes.ADD_VENDOR_DETAILS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload }
      };
    case ActionTypes.ON_LOADING_VENDOR:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_VENDOR_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload || "Something went wrong",
        loading: false
      };
    case ActionTypes.CLEAR_VENDOR_DETAILS:
      return initialState;

    default:
      return state;
  }
};

export default vendorReducers;
