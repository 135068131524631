import axiosInstance from "../utils/helper";
import {
  createVendorType,
  updateVendorType,
  MarketerType,
  AddressType,
  ContactType,
  BankType,
  searchType,
  uploadType,
  extraUploadType,
  OwnerType,
  TotType
} from "../types/apiTypes";
import { getFormData } from "../utils/helperFunctions";
import { idPropType } from "../types/commonTypes";

const VENDOR_API = {
  createVendor: (params: createVendorType) => {
    return axiosInstance.post("/vendors/", params);
  },
  postTotDetails: (params: updateVendorType, id: idPropType) => {
    return axiosInstance.post(`/vendors/${id}/terms-of-trade`, { ...params });
  },
  putTotDetails: (totId: string | number, params: updateVendorType, id: idPropType) => {
    return axiosInstance.put(`/vendors/${id}/terms-of-trade/${totId}`, { ...params });
  },
  uploadDocument: (params: uploadType, id: idPropType) => {
    const data = getFormData({ ...params });
    return axiosInstance.post(`vendors/${id}/upload-doc`, data);
  },
  getVendorDetails: (id: idPropType) => {
    return axiosInstance.get(`/vendors/${id}/`);
  },
  getVendorHistory: (id: idPropType) => {
    return axiosInstance.get(`/vendors/${id}/vendor-logs/`);
  },
  getVendors: (params: Record<string, unknown>) => {
    return axiosInstance.get(`/vendors/`, { params });
  },
  deleteVendorField: (id: idPropType, params: { [x: string]: string }) => {
    return axiosInstance.put(`vendors/${id}/`, params);
  },
  getVendorSubmodule: (params: Record<string, unknown>, type: string, id: idPropType) => {
    return axiosInstance.get(`/vendors/${id}/${type}`, { params });
  },
  getGstinDetails: (id: idPropType, gstin: string) => {
    return axiosInstance.get(`/vendors/${id}/get-gstin-detail/${gstin}/`);
  },
  updateVendorSubModule: (
    params: MarketerType | AddressType | ContactType | BankType | OwnerType | TotType,
    type: string,
    id: idPropType
  ) => {
    return axiosInstance.post(`/vendors/${id}/${type}`, { ...params });
  },
  updateVendor: (params: updateVendorType, id: idPropType) => {
    return axiosInstance.put(`/vendors/${id}/`, { ...params });
  },
  approveVendor: (id: idPropType) => {
    return axiosInstance.post(`/vendors/${id}/approve`);
  },
  changeStatus: (
    id: idPropType,
    params: {
      status: string;
      reason?: string;
    }
  ) => {
    return axiosInstance.post(`/vendors/${id}/change-status`, params);
  },
  editVendor: (
    params:
      | MarketerType
      | AddressType
      | ContactType
      | BankType
      | OwnerType
      | TotType
      | { deleted_at: string },
    type: string,
    typeId?: string | number,
    id?: idPropType // vendor_id
  ) => {
    return axiosInstance.put(`/vendors/${id}/${type}/${typeId}`, { ...params });
  },
  deleteVendorDetail: (type: string, typeId: string | number, vendor_id: idPropType) => {
    return axiosInstance.delete(`/vendors/${vendor_id}/${type}/${typeId}`);
  },
  searchField: (
    params: searchType,
    q: { search_query: string; is_draft?: number; type?: string; pan?: string } | null
  ) => {
    return axiosInstance.get(`/vendors/search/${params.type}`, {
      params: { ...q }
    });
  },
  downloadDoc: (params: { file_path: string }, id: idPropType) => {
    return axiosInstance.get(`/vendors/${id}/download-doc`, { params });
  },
  getAddresses: (id: string) => {
    return axiosInstance.get(`/vendors/${id}/address`);
  },
  getAddressByPin: (val: string) => {
    return axiosInstance.get(`/vendors/pincodes/${val}`);
  },
  getTotDetails: (id: idPropType) => {
    return axiosInstance.get(`/vendors/${id}/terms-of-trade`);
  },
  uploadExtraDocument: (params: extraUploadType, id: idPropType) => {
    return axiosInstance.post(`vendors/${id}/extra-docs`, params);
  },
  updateVendorExtraDoc: (params: extraUploadType, id: idPropType, docId: number) => {
    return axiosInstance.put(`vendors/${id}/extra-docs/${docId}`, params);
  }
};

export default VENDOR_API;
