import { Spin } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../redux";
import { getVendorDetails, getVendorPaymentDetails } from "../redux/action-creators";
import { hasRequiredToken } from "./constants";
import axiosInstance from "../utils/helper";
import ErrorResult from "../pages/ErrorResult";

export const checkTokenChangedOrNot = () => {
  if (hasRequiredToken()) {
    const [JWT_TOKEN, USER_ID] = hasRequiredToken() as string[];
    localStorage.setItem("JWT_TOKEN", JWT_TOKEN);
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${JWT_TOKEN}`;
    localStorage.setItem("USER_ID", USER_ID);
    axiosInstance.defaults.headers.common["USERID"] = USER_ID;
    return true;
  }
  return false;
};

export const VendorWrapper = ({ children }: { children: JSX.Element }) => {
  const vendorDetails = useSelector<RootState>((state) => state.vendor) as {
    loading: boolean;
    error: string;
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkTokenChangedOrNot()) {
      const vendor_id = id as string;
      if (vendor_id) dispatch(getVendorDetails(vendor_id));
    }
  }, []);

  if (vendorDetails?.error) {
    return <ErrorResult message={vendorDetails?.error} />;
  } else return <Spin spinning={vendorDetails?.loading}>{children}</Spin>;
};

export const PaymentWrapper = ({ children }: { children: JSX.Element }) => {
  const payment = useSelector<RootState>((state) => state.payment) as {
    loading: boolean;
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkTokenChangedOrNot()) if (id) dispatch(getVendorPaymentDetails(id as string));
  }, []);

  if (payment?.loading) return <Spin spinning={payment?.loading} tip="Loading..."></Spin>;
  else return children;
};
