export const patternRegex = {
  gstin: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  aadhar: /^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/,
  pan: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  latitude: /^[-]?(([0-8]?[0-9])\.(\d+))|(90(\.0+)?)$/,
  longitude: /^[-]?((((1[0-7][0-9])|([0-9]?[0-9]))\.(\d+))|180(\.0+)?)$/,
  ifsc: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  contact: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  positiveNumber: /^\d{0,10}(\.\d{0,2})?$/,
  mobileNumber: /^[0-9]{10}$/,
  char: /^[a-zA-Z\s]*$/,
  accountNumber: /^\d{9,18}$/,
  swiftcode: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
};
