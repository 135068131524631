import { vendorTypeProps } from "../../types/commonTypes";
import { ActionTypes } from "../action-types";
import { Action } from "../actions";
export interface vendorState {
  error: string | null;
  data: vendorTypeProps;
  loading: boolean;
}

const initialState = {
  data: {} as vendorState["data"],
  error: null,
  loading: false
};

const departmentReducers = (state: vendorState = initialState, action: Action): vendorState => {
  switch (action.type) {
    case ActionTypes.ADD_DEPARTMENTS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default departmentReducers;
