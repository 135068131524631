import { roleActions } from ".";
import NotAuthorized from "../pages/NotAuthorized";
import { ElementType, PermissionsGateProps } from "../types/permissionTypes";
import { getPermissionsData } from "../utils/helperFunctions";

const PermissionsGate = ({
  id,
  type,
  children,
  name,
  allowedActions,
  action
}: PermissionsGateProps) => {
  const permissions: string[] | undefined = getPermissionsData();

  if (allowedActions && action && allowedActions?.length > -1 && action?.length > -1) {
    const permissionsData = {
      permissions: new Set(action)
    };

    const role = localStorage.getItem("ROLE");

    if (role === "admin") return children;

    const rolePermissions = roleActions[role as keyof typeof roleActions];

    if (type === ElementType.PAGE) {
      if (permissions?.includes(id)) {
        for (let i = 0; i < allowedActions.length; i++) {
          if (permissionsData?.permissions?.has(allowedActions[i])) return children;
        }
      }

      return <NotAuthorized />;
    }

    if (type === ElementType.BUTTON) {
      if (permissions?.includes(id)) {
        for (let i = 0; i < allowedActions.length; i++) {
          if (
            permissionsData?.permissions?.has(allowedActions[i]) &&
            rolePermissions?.includes(allowedActions[i])
          )
            return children;
        }
      }
      return <></>;
    }
  }

  if (permissions?.includes("can-read-all")) {
    return <>{children}</>;
  }

  if (type === ElementType.PAGE) {
    if (permissions?.includes(id)) {
      return children;
    }
    return <NotAuthorized pageName={name} />;
  }

  if (type === ElementType.BUTTON) {
    if (permissions?.includes(id)) {
      return children;
    }
    return <></>;
  }

  if (permissions?.includes(id)) {
    return children;
  }
  return <>Not Authorized</>;
};

export default PermissionsGate;

export const hasPermission = (id: string) => {
  const permissions: string[] | undefined = getPermissionsData();

  if (permissions?.includes("can-read-all")) {
    return true;
  }

  if (permissions?.includes(id)) {
    return true;
  }
  return false;
};
