import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { redirect } from "../utils/helperFunctions";

const NotAuthorized = ({ pageName = "this" }: { pageName?: string }) => {
  const navigate = useNavigate();
  const ROLE = localStorage.getItem("ROLE") || "maker";

  return (
    <Result
      status="403"
      title="403"
      subTitle={`Sorry, you are not authorized to access ${pageName} page.`}
      extra={
        <Button type="primary" onClick={() => redirect(ROLE, navigate)}>
          Back Home
        </Button>
      }
    />
  );
};

export default NotAuthorized;
