import { useEffect } from "react";
import { events } from "./utils/constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppLogout = ({ children }: { children: any }) => {
  let timer: string | number | NodeJS.Timeout | undefined;

  // this function sets the timer that logs out the user after 3600000 milliseconds (1 hour)
  const handleLogoutTimer = () => {
    // use session storage to store the time when the user was last active
    // this is used to calculate the time when the user was last active
    // and then set the timer accordingly
    const lastActive = sessionStorage.getItem("lastActive");
    const now = new Date().getTime();
    const timeDiff = now - (lastActive ? parseInt(lastActive) : now);
    const timeLeft = 24 * 3600 * 1000 - timeDiff;

    // if the time left is less than 0, then the user has been inactive for more than 10 secs
    // so we log them out
    if (timeLeft < 0) {
      // log out the user
      logoutAction();
    }
  };

  // this function logs out the user
  const logoutAction = () => {
    // clear the timer
    clearTimeout(timer as NodeJS.Timeout);
    // clear the session storage
    sessionStorage.clear();
    localStorage.clear();
    // redirect the user to the login page
    window.location.href = "/login";
    // log out the user
    // logout();
  };

  // this function sets the last active time in the session storage
  const handleLastActive = () => {
    sessionStorage.setItem("lastActive", new Date().getTime().toString());
  };

  // this function sets the timer to log out the user after 10 secs
  // and also sets the last active time in the session storage
  const handleUserActivity = () => {
    handleLogoutTimer();
    handleLastActive();
  };

  // this function clears the timer when the user is inactive
  const handleUserInactive = () => {
    clearTimeout(timer as NodeJS.Timeout);
  };

  // this function sets the timer to log out the user after 10 secs
  // and also sets the last active time in the session storage
  // when the user is active
  useEffect(() => {
    events.forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });
    // this function clears the timer when the user is inactive
    window.addEventListener("blur", handleUserInactive);
    return () => {
      events.forEach((event) => {
        document.addEventListener(event, handleUserActivity);
      });
      window.removeEventListener("blur", handleUserInactive);
    };
  }, []);

  return children;
};

export default AppLogout;
