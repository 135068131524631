import { Dispatch } from "redux"; //for dispatch type for gving type for typescript
import { Action } from "../actions";
import { ActionTypes } from "../action-types";
import VENDOR_API from "../../api/vendorApis";
import { LoginSuccessResponse, paymentTypes } from "../../types/commonTypes";
import VENDOR_PAYMENT_API from "../../api/paymentApis";
import { showErrorToast } from "../../utils/helperFunctions";
import axiosInstance from "../../utils/helper";
import DEPARTMENTS_API from "../../api/departmentApis";

export const getVendorDetails = (id?: string | number) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: ActionTypes.ON_LOADING_VENDOR
      });
      const response = await VENDOR_API.getVendorDetails(id as string);
      dispatch({
        type: ActionTypes.ADD_VENDOR_DETAILS,
        payload: response?.data
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch({
        type: ActionTypes.GET_VENDOR_DETAILS_FAILURE,
        payload: error.message
      });
    }
  };
};

export const getVendorPaymentDetails = (id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: ActionTypes.ON_LOADING_PAYMENT
      });
      const response = await VENDOR_PAYMENT_API.getPayment(id);
      dispatch({
        type: ActionTypes.ADD_PAYMENT_DETAILS,
        payload: response?.data
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch({
        type: ActionTypes.GET_VENDOR_DETAILS_FAILURE,
        payload: error.message
      });
    }
  };
};

export const addVendorDetails = (data: any) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.ADD_VENDOR_DETAILS,
      payload: data
    });
  };
};

export const addPaymentDetails = (data: paymentTypes) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.ADD_PAYMENT_DETAILS,
      payload: data
    });
  };
};

export const addDepartments = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await DEPARTMENTS_API.getDepartments();

      dispatch({
        type: ActionTypes.ADD_DEPARTMENTS,
        payload: response
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch({
        type: ActionTypes.GET_VENDOR_DETAILS_FAILURE,
        payload: error.message
      });
    }
  };
};

export const handleLoginSuccess = (data: LoginSuccessResponse) => {
  if (data?.user?.roles?.length > 0 && data?.user?.is_active) {
    localStorage.setItem("USER_DETAILS", JSON.stringify(data?.user));
    localStorage.setItem("ACCESS_TOKENS", JSON.stringify(data?.access_tokens));
    const ROLE = localStorage.getItem("ROLE");
    const ACCESS_TOKENS = data?.access_tokens;
    const role = ROLE ? ROLE : data?.user?.roles?.[0]?.role;
    role && localStorage.setItem("ROLE", role);
    localStorage.setItem("USER_ID", JSON.stringify(data?.user?.id));
    const JWT_TOKEN = ACCESS_TOKENS[role as keyof typeof ACCESS_TOKENS];
    if (JWT_TOKEN) {
      localStorage.setItem("JWT_TOKEN", `${JWT_TOKEN}`);
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${JWT_TOKEN}`;
    }
  } else {
    // error
    showErrorToast("User is not active or has no role, please contact admin");
    localStorage.clear();
  }
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.LOGIN_SUCCESS,
      payload: data
    });
  };
};
