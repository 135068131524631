import { ReactElement } from "react";

export enum ElementType {
  PAGE = "PAGE",
  BUTTON = "BUTTON",
  OTHER = "OTHER"
}

export interface PermissionsGateProps {
  id: string;
  type: ElementType;
  name: string;
  children: ReactElement;
  actions?: string[];
  allowedActions?: string[];
  action?: string[];
}

export interface PermissionsData {
  id: number;
  name: string;
  email: string;
  mobile: null | string;
  isAdmin: boolean;
  permissions: string[];
}
