import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { redirect } from "../utils/helperFunctions";

const ErrorResult = ({ pageName = "this", message }: { pageName?: string; message: string }) => {
  const navigate = useNavigate();
  const ROLE = localStorage.getItem("ROLE") || "maker";

  return (
    <Result
      status="500"
      title="Error"
      subTitle={`${message ? message : "Sorry, something went wrong."}`}
      extra={
        <Button type="primary" onClick={() => redirect(ROLE, navigate)}>
          Back Home
        </Button>
      }
    />
  );
};

export default ErrorResult;
