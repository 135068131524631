import axios from "axios";
const AppURL = "vendor.1knetworks.com";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = AppURL;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "true";
// Next we make an 'instance' of it
const instance = axios.create({
  baseURL: "https://vendor-api.1knetworks.com/v1"
});

function handleSuccess(response: { data: unknown }) {
  return { data: response.data };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleError(error: any) {
  if (error.message === "Network Error") {
    // The user doesn't have internet
    return Promise.reject(error);
  }
  switch (error.response.status) {
    case 400:
      break;
    case 401:
      break;
    case 404:
      break;
    case 500:
      break;
    default:
      break;
  }
  return Promise.reject(error);
}

instance.interceptors.response.use(handleSuccess, handleError);

export default instance;
