import { combineReducers } from "redux";
import authReducers from "./auth";
import departmentReducers from "./department";
import paymentReducers from "./payment";
import vendorReducers from "./vendor";

const reducers = combineReducers({
  vendor: vendorReducers,
  payment: paymentReducers,
  auth: authReducers,
  department: departmentReducers
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
