import { ElementType } from "../types/permissionTypes";

const config = {
  PAGE: {
    // PUTAWAY START
    VENDOR_CREATE: {
      id: "can-create-vendor",
      type: ElementType.PAGE,
      name: "Vendor Create"
    },
    READ_VENDOR: {
      id: "can-read-vendor",
      type: ElementType.PAGE,
      name: "Vendor"
    },
    READ_FINANCE_DETAILS: {
      id: "can-read-financial-details",
      type: ElementType.PAGE,
      name: "Financial Details"
    },
    PAYMENT_CREATE: {
      id: "can-create-payment",
      type: ElementType.PAGE,
      name: "Vendor Payment Create"
    },
    READ_PAYMENT: {
      id: "can-read-payment",
      type: ElementType.PAGE,
      name: "Payment"
    },
    PAY_PAYMENT: {
      id: "can-pay-payment",
      type: ElementType.PAGE,
      name: "Payment"
    },
    READ_USER: {
      id: "can-read-user",
      type: ElementType.PAGE,
      name: "Payment"
    }
  },
  HTML_ELEMENTS: {
    ALL_ROUTE: {
      CREATE_VENDOR: {
        id: "can-create-vendor",
        type: ElementType.BUTTON,
        name: "Create Vendor"
      },
      APPROVE_VENDOR: {
        id: "can-approve-vendor",
        type: ElementType.BUTTON,
        name: "approve Vendor"
      },
      CREATE_PAYMENT: {
        id: "can-create-payment",
        type: ElementType.BUTTON,
        name: "Create Payment"
      },
      PAYER_FILTERS: {
        id: "can-pay-payment",
        type: ElementType.BUTTON,
        name: "Pay Payment"
      },
      REJECT_PAYMENT: {
        id: "can-reject-payment",
        type: ElementType.BUTTON,
        name: "Reject Payment"
      },
      REVISE_PAYMENT: {
        id: "can-revise-payment",
        type: ElementType.BUTTON,
        name: "Reject Payment"
      },
      APPROVE_PAYMENT: {
        id: "can-approve-payment",
        type: ElementType.BUTTON,
        name: "Reject Payment"
      },
      PAYMENT_ACTION_FL1: {
        id: "can-perform-action-fl1",
        type: ElementType.BUTTON,
        name: "Reject Payment"
      },
      PAYMENT_ACTION_FL2: {
        id: "can-perform-action-fl2",
        type: ElementType.BUTTON,
        name: "Reject Payment"
      },
      READ_FINANCE_DETAILS: {
        id: "can-read-financial-details",
        type: ElementType.BUTTON,
        name: "Financial Details"
      }
    }
  }
};

export default config;
