import { Button, Col, Row, Select, Space } from "antd";
import { LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import useRedirection from "../../hooks/useRedirection";
import { LoadingContext } from "../../App";
import { redirect } from "../../utils/helperFunctions";
import { ProSettings } from "@ant-design/pro-layout";
import DrakTheme from "../../assests/darkTheme.png";
import LightTheme from "../../assests/sun.png";
import EveningTheme from "../../assests/evening.png";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.css";
const { Option } = Select;
interface TopBarProps {
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  collapsed: boolean;
  setSetting: React.Dispatch<React.SetStateAction<Partial<ProSettings> | undefined>>;
}

function TopBar({ collapsed, setCollapsed, setSetting }: TopBarProps) {
  const USER_DETAILS = localStorage.getItem("USER_DETAILS");
  const ROLE = localStorage.getItem("ROLE");
  const { name, employee_code, roles } = JSON.parse(USER_DETAILS || "[]");

  const search = useLocation().search;
  const navTheme = new URLSearchParams(search).get("navTheme");
  const [url, setUrl] = useState("");
  const { showLoading } = React.useContext(LoadingContext);
  const [theme, setTheme] = useState(navTheme || "");
  useRedirection(url);

  const logout = () => {
    //clearing local storage
    localStorage.clear();
    setUrl("/login");
  };

  const changeRole = (value: string) => {
    ///changing role
    const ACCESS_TOKENS = localStorage.getItem("ACCESS_TOKENS");
    if (ACCESS_TOKENS) localStorage.setItem("JWT_TOKEN", JSON.parse(ACCESS_TOKENS)?.[value]);
    localStorage.setItem("ROLE", value);
    showLoading();
    setTimeout(() => {
      redirect(value, (val: string) => {
        if (theme) window.location.href = `${val}/?navTheme=${theme}`;
        else window.location.href = `${val}/`;
      });
    }, 0);
  };

  return (
    <div style={{ background: "white", padding: "18px 24px" }}>
      <Row style={{ justifyContent: "space-between" }}>
        <Col xs={24} md={12} className="d-flex align-items-center gap-15">
          <Space size="large">
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed)
            })}
            <Select
              size="large"
              placeholder="ROLE"
              className={"capitalize " + styles.topbarRoleSelect}
              onChange={(value) => changeRole(value)}
              defaultValue={ROLE}>
              {roles?.map((item: { role: string }, i: number) => (
                <Option value={item?.role} key={i} className="capitalize">
                  {item?.role?.split("_")?.join(" ")}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col md={12} xs={24} style={{ textAlign: "right" }}>
          <Space size="large">
            {/* theme switcher buttons */}
            <Button
              size="large"
              type="text"
              onClick={() => {
                setSetting((prev) => ({
                  ...prev,
                  navTheme: theme === "light" ? "realDark" : theme === "realDark" ? "dark" : "light"
                }));
                setTheme(theme === "light" ? "realDark" : theme === "realDark" ? "dark" : "light");
              }}
              icon={
                theme === "light" ? (
                  <img src={LightTheme} alt="light" className="custom-theme-icon" />
                ) : theme === "realDark" ? (
                  <img src={DrakTheme} alt="dark" className="custom-theme-icon" />
                ) : (
                  <img src={EveningTheme} className="custom-theme-icon" />
                )
              }
            />
            <span>
              {name} {employee_code ? ` (${employee_code})` : null}
            </span>
            <Button onClick={logout} type="link" style={{ color: "black", padding: "3px" }}>
              <LogoutOutlined />
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
}

export default TopBar;
