import { Suspense, useEffect, useState } from "react";
import "./App.less";
import { useRoutes } from "react-router-dom";
import routing from "./routing";
import { Spin } from "antd";
import axiosInstance from "./utils/helper";
import { hasRequiredToken } from "./utils/constants";
import React from "react";
import AppLogout from "./AppLogout";
import { showErrorToast } from "./utils/helperFunctions";
import { useTypedSelector } from "./hooks/useTypeSelector";
import { useAction } from "./hooks/useActions";

export const LoadingContext = React.createContext({
  loading: false,
  message: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showLoading: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideLoading: () => {}
});

const App = () => {
  const { isLoggedIn } = useTypedSelector((state) => state?.auth);
  const [loading, setLoading] = useState(true);
  const { handleLoginSuccess } = useAction();

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  // initial cookie setup
  useEffect(() => {
    const checkTokenChangedOrNot = () => {
      if (hasRequiredToken()) {
        const JWT_TOKEN = localStorage.getItem("JWT_TOKEN");
        const ACCESS_TOKENS = localStorage.getItem("ACCESS_TOKENS");
        if (JWT_TOKEN) {
          axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${JWT_TOKEN}`;
        }
        if (!isLoggedIn) {
          const data = localStorage.getItem("USER_DETAILS");
          if (data) {
            const formattedData = JSON.parse(data);
            const formattedTokens = JSON.parse(ACCESS_TOKENS as string);
            handleLoginSuccess({ access_tokens: formattedTokens, user: formattedData });
          }
        }
      }
      setLoading(false);
    };
    checkTokenChangedOrNot();
  }, []);

  // Api response check for Instance
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response) {
        // For unauthorized ie if 401, then redirecting to logout page of Old Admin Panel
        const { status, statusText } = error.response;
        if (status === 401 || statusText === "Unauthorized") {
          localStorage.clear();
          showErrorToast("Your session has expired, please login again!");
          window.location.reload();
        }
      }

      return Promise.reject(error);
    }
  );

  const content = useRoutes(routing(isLoggedIn));

  return (
    <div>
      <LoadingContext.Provider value={{ loading, message: null, showLoading, hideLoading }}>
        <>
          {loading ? (
            <FallbackUI />
          ) : (
            <AppLogout>
              <Suspense fallback={<FallbackUI />}>{content}</Suspense>
            </AppLogout>
          )}
        </>
      </LoadingContext.Provider>
    </div>
  );
};

export default App;

export const FallbackUI = () => {
  return (
    <div className="fallback-ui">
      <Spin tip="Loading..." />
    </div>
  );
};
