import { LoginSuccessResponse } from "../../types/commonTypes";
import { ActionTypes } from "../action-types";
import { Action } from "../actions";

const initialState = {
  data: null,
  error: null,
  isLoggedIn: false
};

interface authState {
  error: string | null;
  data: LoginSuccessResponse | null;
  isLoggedIn: boolean;
}

const authReducers = (state: authState = initialState, action: Action): authState => {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoggedIn: true
      };

    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        data: null,
        error: "Login Failed",
        isLoggedIn: false
      };
    default:
      return state;
  }
};

export default authReducers;
