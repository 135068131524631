import { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
// import Department from "./Components/Department";
import Layout from "./Components/Layout";
import PermissionsGate from "./permissions/PermissionGate";
import config from "./permissions/PermissionsConfig";
import { VendorWrapper } from "./utils/Wrapper";
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/Signup"));
const UsersList = lazy(() => import("./pages/Users"));
// const FinanceDetails = lazy(() => import("./pages/VendorPayments/RequestDetails/FinanceDetails"));
// const PaymentRequest = lazy(() => import("./pages/VendorPayments/PaymentRequest"));
// const RequestDetails = lazy(() => import("./pages/VendorPayments/RequestDetails"));
const Vendor = lazy(() => import("./pages/Vendors/Vendor"));
const VendorPayment = lazy(() => import("./pages/VendorPayments"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Vendors = lazy(() => import("./pages/Vendors"));
const Marketers = lazy(() => import("./pages/Vendors/Vendor/Marketers"));
const MarketerDetails = lazy(() => import("./pages/Vendors/Vendor/Marketers/MarketerDetails"));
const Contacts = lazy(() => import("./pages/Vendors/Vendor/Contacts"));
const ContactDetails = lazy(() => import("./pages/Vendors/Vendor/Contacts/ContactDetails"));
const Addresses = lazy(() => import("./pages/Vendors/Vendor/Addresses"));
const AddressDetails = lazy(() => import("./pages/Vendors/Vendor/Addresses/AddressDetails"));
const Banks = lazy(() => import("./pages/Vendors/Vendor/Banks"));
const BankDetails = lazy(() => import("./pages/Vendors/Vendor/Banks/BankDetails"));
const Owners = lazy(() => import("./pages/Vendors/Vendor/Owners"));
const OwnerDetails = lazy(() => import("./pages/Vendors/Vendor/Owners/OwnerDetails"));

const getElement = (isLoggedIn: boolean) => {
  if (isLoggedIn) {
    const ROLE = localStorage.getItem("ROLE") as string;
    switch (ROLE) {
      case "maker":
        return <Navigate to="/vendors/vendor-list" />;
      case "admin":
        return <Navigate to="/users/all" />;
      case "user_manager":
        return <Navigate to="/users/all" />;
      case "payer":
        return <Navigate to="/vendor-payments/all" />;
      default:
        return <Navigate to="/vendors/vendor-list" />;
    }
  }

  return <Navigate to="/login" />;
};

const getElementsForAdmin = (isLoggedIn: boolean) => {
  // let department = localStorage.getItem("USER_DETAILS");
  // if (department) {
  //   department = JSON.parse(department)?.department;
  // }
  // if ((department === null || department === undefined) && isLoggedIn) {
  //   return <Department />;
  // }
  if (isLoggedIn) {
    return <Layout />;
  }

  return <Navigate to="/login" />;
};

const routes = (isLoggedIn: boolean) => [
  //without Layout
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: getElement(isLoggedIn)
      },
      {
        path: "login",
        element: !isLoggedIn ? <Login /> : <Navigate to="/" />
      },
      {
        path: "signup",
        element: !isLoggedIn ? <SignUp /> : <Navigate to="/" />
      },
      {
        path: "your-permissions",
        element: "You don't have Permissions, Please contact your admin"
      },
      { path: "*", element: <NotFound /> }
    ]
  },

  //with Layout
  {
    path: "/",
    element: <Navigate to="/" />
  },
  {
    path: "/vendors",
    element: getElementsForAdmin(isLoggedIn),
    children: [
      {
        path: "/vendors/vendor-list",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <Vendors />
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <Vendor />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/marketers",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <Marketers />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/marketers/:marketerId",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <MarketerDetails />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/contacts",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <Contacts />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/contacts/:contactId",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <ContactDetails />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/addresses",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <Addresses />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/addresses/:addressId",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <AddressDetails />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/owners",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <Owners />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/owners/:ownerId",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <OwnerDetails />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/banks",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <Banks />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      {
        path: "/vendors/vendor-list/:id/banks/:bankId",
        element: (
          <PermissionsGate {...config.PAGE.READ_VENDOR}>
            <VendorWrapper>
              <BankDetails />
            </VendorWrapper>
          </PermissionsGate>
        )
      },
      { path: "*", element: <NotFound /> }
    ]
  },
  // {
  //   path: "/purchase-orders",
  //   element: getElementsForAdmin(isLoggedIn)
  // },
  // {
  //   path: "/purchase-invoices",
  //   element: getElementsForAdmin(isLoggedIn)
  // },
  // {
  //   path: "/payment-requests",
  //   element: getElementsForAdmin(isLoggedIn)
  // },
  // {
  //   path: "/department",
  //   element: (
  //     <PermissionsGate {...config.PAGE.READ_PAYMENT}>
  //       <Department />
  //     </PermissionsGate>
  //   )
  // },
  {
    path: "/vendor-payments",
    element: getElementsForAdmin(isLoggedIn),
    children: [
      {
        path: "/vendor-payments/all",
        element: (
          <PermissionsGate {...config.PAGE.READ_PAYMENT}>
            <VendorPayment />
          </PermissionsGate>
        )
      }
      // {
      //   path: "/vendor-payments/request",
      //   element: (
      //     <PermissionsGate {...config.PAGE.PAYMENT_CREATE}>
      //       <PaymentRequest />
      //     </PermissionsGate>
      //   )
      // },
      // {
      //   path: "/vendor-payments/:id",
      //   element: (
      //     <PermissionsGate {...config.PAGE.READ_PAYMENT}>
      //       <PaymentWrapper>
      //         <RequestDetails />
      //       </PaymentWrapper>
      //     </PermissionsGate>
      //   )
      // },
      // {
      //   path: `/vendor-payments/:id/finance-details`,
      //   element: (
      //     <PermissionsGate {...config.PAGE.READ_FINANCE_DETAILS}>
      //       <PaymentWrapper>
      //         <FinanceDetails />
      //       </PaymentWrapper>
      //     </PermissionsGate>
      //   )
      // }
    ]
  },
  {
    path: "/users",
    element: getElementsForAdmin(isLoggedIn),
    children: [
      {
        path: "/users/all",
        element: (
          <PermissionsGate {...config.PAGE.READ_USER}>
            <UsersList />
          </PermissionsGate>
        )
      },
      { path: "*", element: <NotFound /> }
    ]
  }
];

export default routes;
