import { ActionTypes } from "../action-types";
import { Action } from "../actions";

const initialState = {
  data: {},
  error: null,
  loading: true
};
interface paymentState {
  error: string | null;
  data: object;
  loading: boolean;
}

const paymentReducers = (state: paymentState = initialState, action: Action): paymentState => {
  switch (action.type) {
    case ActionTypes.GET_PAYMENT_DETAILS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case ActionTypes.ADD_PAYMENT_DETAILS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload }
      };
    case ActionTypes.ON_LOADING_PAYMENT:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case ActionTypes.CLEAR_PAYMENT_DETAILS:
      return initialState;

    default:
      return state;
  }
};

export default paymentReducers;
