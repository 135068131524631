import { ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";
import ProLayout, { PageContainer, ProSettings, SettingDrawer } from "@ant-design/pro-layout";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import CustomBreadcrumb from "../CustomBreadcrumb";
import Logo from "../../assests/new-logo.svg";
import TopBar from "../TopBar";
import filterSidebarProps from "./filterSidebarProps";

const Layout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [pathname, setPathname] = useState(location.pathname);
  const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({});

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  // resize effects
  useEffect(() => {
    let x = window.innerWidth;
    function handleResize() {
      x = window.innerWidth;
      if (x < 767) {
        setCollapsed(true);
      }
    }

    handleResize();

    return () => window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div
      id="test-pro-layout"
      style={{
        height: "100vh"
      }}>
      <ProLayout
        {...filterSidebarProps()}
        location={{
          pathname
        }}
        className="vendor-custom-header"
        pageTitleRender={() => "Vendor Portal"}
        title="Vendor Portal"
        logo={Logo}
        headerRender={() => (
          <TopBar collapsed={collapsed} setCollapsed={setCollapsed} setSetting={setSetting} />
        )}
        headerTheme="light"
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        fixSiderbar
        menuHeaderRender={(
          logoDom: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined,
          titleDom: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined
        ) => (
          <div
            onClick={() => navigate("/")}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}>
            {logoDom}
            {titleDom}
          </div>
        )}
        menuItemRender={(
          item: { path: string | undefined },
          dom: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined
        ) => (
          <a
            onClick={() => {
              navigate(item?.path || "/");
            }}>
            {dom}
          </a>
        )}
        navTheme={settings?.navTheme}>
        <PageContainer>
          <CustomBreadcrumb pathname={pathname} />
          <Outlet />
          <SettingDrawer
            defaultSettings={settings}
            pathname={pathname}
            enableDarkTheme
            getContainer={() => document.getElementById("test-pro-layout")}
            settings={settings}
            onSettingChange={(changeSetting) => {
              setSetting(changeSetting);
            }}
            disableUrlParams={false}
            themeOnly={true}
          />
        </PageContainer>
      </ProLayout>
    </div>
  );
};

export default Layout;
