import {
  DashboardOutlined,
  NodeIndexOutlined,
  UserSwitchOutlined
  // PullRequestOutlined
} from "@ant-design/icons";
import config from "../../permissions/PermissionsConfig";

export default {
  route: {
    path: "/",
    routes: [
      {
        path: "/users",
        name: "Users",
        icon: <UserSwitchOutlined />,
        id: true,
        routes: [
          {
            path: "/users/all",
            name: "All Users",
            icon: <NodeIndexOutlined />,
            id: config.PAGE.READ_USER.id
          }
        ]
      },
      {
        path: "/vendors",
        name: "Vendors",
        icon: <DashboardOutlined />,
        id: true,
        routes: [
          {
            path: "/vendors/vendor-list",
            name: "All Vendors",
            icon: <NodeIndexOutlined />,
            id: config.PAGE.READ_VENDOR.id
          }
        ]
      }
      // {
      //   path: "/vendor-payments",
      //   name: "Vendor Payments",
      //   icon: <PullRequestOutlined />,
      //   id: true,
      //   routes: [
      //     {
      //       path: "/vendor-payments/all",
      //       name: "Payments",
      //       icon: <NodeIndexOutlined />,
      //       id: config.PAGE.READ_PAYMENT.id
      //     }
      //   ]
      // }
    ]
  },
  location: {
    pathname: "/"
  }
};
