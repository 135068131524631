import { patternRegex } from "./regexValues";

export const timeToUndoDeleteRowForAddTask = 10;

export const getUniqueKey = () => {
  return Math.random().toString(16).slice(2);
};

export const trimNumbersToAFixedFigit = (value: number, fixedFigit = 2) => {
  return +parseFloat(value.toString()).toFixed(fixedFigit);
};

export const formatDate = (date: string) => {
  try {
    return (
      new Date(date).toDateString() +
      ", " +
      new Date(date).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      })
    );
  } catch (error) {
    console.log(error);
  }
  return "--";
};

export enum uomTypes {
  unit = "unit",
  outer = "outer",
  case = "case"
}

export enum TaskStatus {
  pending = "pending",
  processing = "processing",
  completed = "completed"
}

export enum FilterTypes {
  input = "input",
  date = "date"
}

export const secondsToHms = (d: number) => {
  try {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    const formattedTime = (hDisplay + mDisplay + sDisplay).trim();

    if (formattedTime[formattedTime.length - 1] === ",") {
      return formattedTime.slice(0, formattedTime.length - 1);
    }
    return formattedTime;
  } catch (error) {
    console.log(error);
  }

  return "invalid Time";
};

//getting the token from cookies
export const hasRequiredToken = () => {
  try {
    const USER_DETAILS = localStorage.getItem("USER_DETAILS");
    const ACCESS_TOKENS = localStorage.getItem("ACCESS_TOKENS");
    const ROLE = localStorage.getItem("ROLE");
    if (USER_DETAILS && ACCESS_TOKENS && ROLE) {
      const JWT_TOKEN = JSON.parse(ACCESS_TOKENS)?.[ROLE];
      const USER_ID = JSON.parse(USER_DETAILS)?.id;
      if (!JWT_TOKEN || !USER_ID || !ROLE) return false;
      return [JWT_TOKEN, USER_ID];
    }
    return false;
  } catch (error) {
    console.log("Error", error);
  }

  return false;
};

export const getFormattedTodayDate = () => {
  try {
    const today = new Date().toLocaleDateString("en-GB");
    const split = today.split("/");
    return split[0] + "-" + split[1] + "-" + split[2];
  } catch (error) {
    console.log("Error: ", error);
  }
  return "";
};

export const getLastWeekFormattedDate = () => {
  try {
    const today = new Date();
    const prevWeek = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);

    const temp = new Date(prevWeek).toLocaleDateString("en-GB");
    const split = temp.split("/");
    return split[0] + "-" + split[1] + "-" + split[2];
  } catch (error) {
    console.log("Error: ", error);
  }
  return "";
};

//GETTING 17-03-2022 AND SENDS 22-03-17
export const changeDDMMYYYYToYYMMDD = (date: string) => {
  try {
    const split = date.split("-");
    return split[2].slice(2) + "-" + split[1] + "-" + split[0];
  } catch (error) {
    console.log("Error: ", error);
  }
  return "";
};

//GETTING 2022-03-20 AND SENDS 20-03-2022
export const changeYYYYMMDDToDDMMYYYY = (date: string) => {
  try {
    const split = date.split("-");
    return split[2] + "-" + split[1] + "-" + split[0];
  } catch (error) {
    console.log("Error: ", error);
  }
  return "";
};

//Tags Colors
export const tagsColor = {
  activeTagColor: "#00C6AE",
  inactiveTagColor: "#D3D3D3",
  disabledTagColor: "#D3D3D3",
  dangerTagColor: "#FF4D4F",
  warningTagColor: "#FAAD14",
  successTagColor: "#52C41A"
};

//allTagsTypes
export const allTags = ["1K", "Club 1K", "Retail", "Semi-Wholesale", "Wholesale"];

export const businessDocumentsInfo = [
  "Only Image and PDF type is allowed",
  "Maximum of 2MB per file is allowed"
];

export const maxSizeForUpload = 2 * 1024 * 1024;

export const timeForToastError = 5; //in seconds

// new vendor steps
export const vendorSteps = (id: string) => {
  return [
    {
      navigateLink: "/vendors/new-vendor",
      navigateEdit: `/vendors/${id}/edit-vendor/`,
      title: "Vendor Creation",
      description: "Create a new vendor"
    },
    {
      navigateLink: `/vendors/${id}/vendor-details/`,
      navigateEdit: `/vendors/${id}/vendor-details/`,
      title: "Vendor Details",
      description: "Marketers, Banks, Addresses, etc."
    },
    {
      navigateLink: `/vendors/${id}/business-details/`,
      navigateEdit: `/vendors/${id}/business-details/`,
      title: "Business Details",
      description: "Documents"
    }
    // {
    //   navigateLink: `/vendors/${id}/terms-of-trade/`,
    //   navigateEdit: `/vendors/${id}/terms-of-trade/`,
    //   title: "Terms of Trade",
    //   description: "Add Payment Terms, Delivery Terms, etc."
    // }
  ];
};

// get payment form fields
export const getPaymentFieldProps = (request: string) => {
  switch (request) {
    case "Outside-Settlement":
      return [
        "payment_type",
        "service_address",
        "vendor_name",
        "is_gstin",
        "vendor_address",
        "invoice_date",
        "payment_date",
        // "vendor_ref_id",
        "invoice_amount",
        "gst_amount",
        "discount_amount",
        "payment_mode",
        "upload_invoice",
        "remarks"
      ];
    case "Trade-Advance":
      return [
        "payment_type",
        "invoice_type",
        "po_id",
        "warehouse",
        "vendor_name",
        "is_gstin",
        "vendor_address",
        "po_date",
        "payment_due_date",
        "vendor_ref_id",
        "po_amount",
        "advance_amount",
        "vendor_bank_details",
        "upload_po"
      ];
    case "Trade-Post Invoice":
      return [
        "payment_type",
        "invoice_type",
        "invoice_id",
        "warehouse",
        "vendor_name",
        "is_gstin",
        "vendor_address",
        "invoice_date",
        "payment_due_date",
        "vendor_ref_id",
        "invoice_amount",
        "gst_amount",
        "discount_amount",
        "debit_note_amount",
        "vendor_bank_details",
        "upload_invoice"
      ];
    case "Non-Trade-Advance":
      return [
        "payment_type",
        "invoice_type",
        "service_address",
        "vendor_name",
        "is_pan",
        "vendor_address",
        "payment_due_date",
        // "vendor_ref_id",
        "invoice_amount",
        "gst_amount",
        "advance_amount",
        "vendor_bank_details",
        "upload_po"
      ];
    case "Non-Trade-Post-Invoice":
      return [
        "payment_type",
        "invoice_type",
        "service_address",
        "vendor_name",
        "is_pan",
        "vendor_address",
        "invoice_date",
        "payment_due_date",
        // "vendor_ref_id",
        "invoice_amount",
        "gst_amount",
        "discount_amount",
        "vendor_bank_details",
        "payment_mode",
        "upload_invoice"
      ];
    default:
      return [];
  }
};

export const getRole = () => {
  return localStorage.getItem("ROLE") || "";
};

const bank_mendatory = ["bank_name", "account_number", "is_active"];
const address_mentatory = [
  "address_type",
  "address",
  "pincode",
  "country",
  "is_active",
  "contact_id",
  "default_contact_id"
];

export const REQUIRED_VENDOR_DETAILS = {
  MARKETER_FIELDS: ["relationship", "name"],
  ADDRESS_FIELDS_INTERNATIONAL: [...address_mentatory],
  ADDRESS_FIELDS: [
    ...address_mentatory,
    "bank_account_id",
    "default_bank_account_id",
    "district",
    "state"
  ],
  ADDRESS_FIELDS_NONGST: [
    ...address_mentatory,
    "bank_account_id",
    "default_bank_account_id",
    "district",
    "state"
  ],
  CONTACT_FIELDS: [
    "name",
    "contact_number",
    "email",
    "country_code",
    "contact_status",
    "designation"
  ],
  OWNER_FIELDS: ["name", "contact_number", "email", "country_code", "employee_id"],
  CONTACT_FIELDS_INTERNATIONAL: ["name", "email", "contact_status", "designation"],
  BANK_FIELDS: [...bank_mendatory, "ifsc_code", "cancelled_cheque_document"],
  BANK_FIELDS_INTERNATIONAL: [...bank_mendatory, "swift_code"]
};

export const charRule = { pattern: patternRegex.char, message: "Please input valid text" };

export const limitTextInput = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: (rule: any, value: any) => {
    if (value && value[0] === " ") {
      return Promise.reject("Please input valid text");
    }
    if (value && (value.length <= 1 || value.length >= 80)) {
      return Promise.reject("Please input min 2 and max 80 characters");
    }
    return Promise.resolve();
  }
};

export const positiveInteger = {
  // pattern for positive number
  pattern: new RegExp(/^[0-9]*$/),
  message: "Please input positive integer!"
};

export const vendor_status = [
  {
    status: "draft",
    id: "0",
    color: "#adadad"
  },
  {
    status: "pending approval",
    id: "1",
    color: "yellow"
  },
  {
    status: "rejected",
    id: "2",
    color: "red"
  },
  {
    status: "inactive",
    id: "3",
    color: "yellow"
  },
  {
    status: "dormant",
    id: "4",
    color: "orange"
  },
  {
    status: "approved",
    id: "5",
    color: "green"
  },
  {
    status: "blocked",
    id: "6",
    color: "red"
  }
];

export const getVendorStatusColor = (status: string) => {
  const vendorStatus = vendor_status.find((item) => item.status === status);
  return vendorStatus ? vendorStatus.color : "#adadad";
};

export const payment_status = [
  {
    status: "pending_maker",
    id: "0",
    color: "yellow"
  },
  {
    status: "pending_fl1",
    id: "1",
    color: "yellow"
  },
  {
    status: "pending_fl2",
    id: "2",
    color: "yellow"
  },
  {
    status: "payer_pending",
    id: "3",
    color: "yellow"
  },
  {
    status: "payment_pending",
    id: "4",
    color: "orange"
  },
  {
    status: "payment_success",
    id: "5",
    color: "green"
  },
  {
    status: "payment_failed",
    id: "6",
    color: "red"
  },
  {
    status: "rejected",
    id: "7",
    color: "red"
  }
];

export const PAYMENT_ACTIONS = {
  EDIT_MAKER: "edit_maker",
  APPROVE_FL1: "approve_fl1",
  REJECT_FL1: "reject_fl1",
  REVISE_FL1_TO_MAKER: "revise_fl1_to_maker",
  APPROVE_FL2: "approve_fl2",
  REJECT_FL2: "reject_fl2",
  REVISE_FL2_TO_MAKER: "revise_fl2_to_maker",
  REVISE_FL2_TO_FL1: "revise_fl2_to_fl1",
  APPROVE_PAYER: "approve_payer",
  REJECT_PAYER: "reject_payer",
  REVISE_PAYER_TO_MAKER: "revise_payer_to_maker",
  REVISE_PAYER_TO_FL1: "revise_payer_to_fl1"
};

export const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
  "touchstart",
  "touchmove"
];

export const pan_error: {
  name: string;
  errors: string[];
  setError: (errors: string) => void;
  clearError: () => void;
  getState: () => {
    name: string;
    errors: string[];
  };
} = {
  name: "pan_number",
  errors: [],
  setError(e: string) {
    this.errors.push(e);
  },
  clearError() {
    this.errors = [];
  },
  getState() {
    return {
      name: this.name,
      errors: this.errors
    };
  }
};

export const PAN_NUMBER = "pan_number";
export const IS_INTERNATIONAL = "is_international";
export const COUNTRY_CODES_URL =
  "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json";
export const RAZORPAY_API = "https://ifsc.razorpay.com/";
export const COUNTRIES_URL = "https://restcountries.com/v3.1/all";

export const ADDRESS_DETAIL_FIELDS = [
  "address",
  "address_type",
  "pincode",
  "country",
  "landmark",
  "gstin_number",
  "is_active",
  "fssai_number",
  "gstin_document",
  "fssai_document",
  "shop_establishment_number",
  "shop_establishment_document"
];

export const CONTACT_DETAIL_FIELDS = [
  "name",
  "email",
  "contact_number",
  "country_code",
  "designation",
  "other_designation",
  "is_primary",
  "contact_status"
];

export const OWNER_DETAIL_FIELDS = [
  "name",
  "contact_number",
  "email",
  "employee_id",
  "country_code"
];

export const BANK_DETAIL_FIELDS = [
  "account_number",
  "bank_name",
  "is_active",
  "ifsc_code",
  "cancelled_cheque_document"
];

export const MARKETER_DETAIL_FIELDS = ["name", "relationship"];

export const isEqual = (value_1: unknown, value_2: unknown) => {
  // check if values are equal
  return JSON.stringify(value_1) == JSON.stringify(value_2);
};

export const APPROVED = "approved";
export const REJECTED = "rejected";
export const INACTIVE = "inactive";
export const DORMANT = "dormant";
export const BLOCKED = "blocked";
export const DRAFT = "draft";
export const PENDING_APPROVAL = "pending approval";

export const ONBOARDING_CHECKER_ACTIONS = [
  {
    label: "Approve",
    value: APPROVED
  },
  {
    label: "Reject",
    value: REJECTED
  },
  {
    label: "Inactive",
    value: INACTIVE
  },
  {
    label: "Dormant",
    value: DORMANT
  },
  {
    label: "Blocked",
    value: BLOCKED
  }
];

export const VENDOR_ONBOARDING_STATUS = [
  {
    label: "Draft",
    value: DRAFT
  },
  {
    label: "Pending Approval",
    value: PENDING_APPROVAL
  },
  ...ONBOARDING_CHECKER_ACTIONS
];
