import { getPermissionsData } from "../../utils/helperFunctions";
import defaultProps from "./_defaultProps";

const filterSidebarProps = () => {
  const localPermissionsData = getPermissionsData();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // console.log(localPermissionsData, "localPermissionsData");
  // const localPermissionsData: string = JSON.stringify(staticPermissionsData);
  // if localPermissions Data present, means, filter the default props, to show only the
  // permissions that are present in the local storage
  if (localPermissionsData) {
    const permissionsData: string =
      localPermissionsData.length > 0 ? localPermissionsData.join(",") : "";
    if (permissionsData?.includes("can-read-all")) {
      return defaultProps;
    }

    const allowedPermissions = permissionsData;
    const allRoutes = [...defaultProps.route.routes];
    const filteredRoutes = [];

    // filtering based on the allowed permissions
    for (let i = 0; i < allRoutes.length; i++) {
      const route = allRoutes[i];

      // for multi type routes ie. nested
      if (route.routes) {
        const copyRoute = { ...route };

        const allowedRoutesInsideCopyRoute = [];

        for (let j = 0; j < route.routes.length; j++) {
          const routeInsideCopyRoute = route.routes[j];

          if (routeInsideCopyRoute.id == "true") {
            allowedRoutesInsideCopyRoute.push(routeInsideCopyRoute);
          } else if (
            routeInsideCopyRoute.id &&
            allowedPermissions.includes(routeInsideCopyRoute.id)
          ) {
            allowedRoutesInsideCopyRoute.push(routeInsideCopyRoute);
          }
        }

        // if allowedRoutesInsideCopyRoute length is more than 0, then will show the nested one,
        // else, whole nesting will be removed

        if (allowedRoutesInsideCopyRoute.length > 0) {
          copyRoute.routes = [...allowedRoutesInsideCopyRoute];

          // adding the route to the filteredRoutes
          filteredRoutes.push(copyRoute);
        }
        // else copyRoute will be empty, and thus will not anything
      }
      // for single type, ie no nesting,  which means will have to check the id
      else {
        // if id= true, then visible for all users, no need to check
        if (route.id == true) {
          filteredRoutes.push(route);
        }
        // for id= someKey, then check if permissions has that key
        if (route.id && typeof route.id === "string" && allowedPermissions?.includes(route?.id)) {
          filteredRoutes.push(route);
        }
      }
    }

    const tempDefaultProps = { ...defaultProps };
    tempDefaultProps.route.routes = [...filteredRoutes];

    return tempDefaultProps;
  }

  //Returning an empty sidebar if the user has no permissions
  return {
    route: {
      path: "/",
      routes: []
    }
  };
};

export default filterSidebarProps;
