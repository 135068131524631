export enum ActionTypes {
  GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS",
  GET_VENDOR_DETAILS_FAILURE = "GET_VENDOR_DETAILS_FAILURE",
  CLEAR_VENDOR_DETAILS = "CLEAR_VENDOR_DETAILS",
  ADD_VENDOR_DETAILS = "ADD_VENDOR_DETAILS",
  ON_LOADING_VENDOR = "ON_LOADING_VENDOR",
  GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS",
  GET_PAYMENT_DETAILS_FAILURE = "GET_PAYMENT_DETAILS_FAILURE",
  CLEAR_PAYMENT_DETAILS = "CLEAR_PAYMENT_DETAILS",
  ADD_PAYMENT_DETAILS = "ADD_PAYMENT_DETAILS",
  ADD_DEPARTMENTS = "ADD_DEPARTMENTS",
  ON_LOADING_PAYMENT = "ON_LOADING_PAYMENT",
  GET_USER_DETAILS = "GET_USER_DETAILS",
  GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE",
  CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS",
  ADD_USER_DETAILS = "ADD_USER_DETAILS",
  ON_LOADING_USER = "ON_LOADING_USER",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE"
}
